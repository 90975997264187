@import "variables";
@import "mixins";
@import "breakpoints";
@import "icons";
@import "keyframes";

.Search {
  position: fixed;
  left: 0;
  top: 0;
  width: 20em;
  height: auto;

  .Search-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(30px);
    opacity: 0;
    transition: opacity 0.5s;
  }

  .Search-window {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 2em;
    width: 20em;
    max-width: 90vw;
    transition: all 0.5s;
    
    @include breakpoint(mobile) {
      transition: none;
    }

    .Search-close {
      @include button(secondary_transparent);
      @include with-icon(x);

      display: none;
      font-size: 2em;
      position: absolute;
      right: 1em;
      border: none;
      padding: 0;
      width: 2em;
      min-width: 0;
      height: 2em;
      line-height: 0.9;

      &::after {
        top: 0;
      }

      @include breakpoint(mobile) {
        width: 1.5em;
        height: 1.5em;
      }
    }

    .Search-directory-toggle {
      display: none;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 1;
      border-radius: 0.2rem;
      right: 6em;
      width: 4em;
      height: 4em;
      cursor: pointer;
      color: var(--color-secondary);

      &.active {
        color: var(--color-white);
        background: var(--color-primary);
      }

      @include breakpoint(mobile) {
        width: 3em;
        height: 3em;
      }
    }

    .Search-input {
      position: relative;
      border-radius: 2em;
      background: var(--color-white);

      .icon {
        @include with-icon(search);
        position: absolute;
        left: 1em;
        top: 1em;
        opacity: 0.5;
        transition: opacity 0.3s;
        font-size: 1.2em;
        color: var(--color-secondary);

        @include breakpoint(mobile) {
          top: 0.5em;
        }
      }

      input {
        @include input;
        position: relative;
        padding-left: 3.2em;
        width: 100%;
        box-sizing: border-box;
        background: none;
        outline: none;
        opacity: 0.5;
        transition: opacity 0.3s;

        @include breakpoint(mobile) {
          line-height: 3em;
        }
      }
    }

    .Search-directory {
      display: none;
      padding-top: 1.5rem;
      padding-left: 1em;
    }

    .Search-results {
      display: none;
      padding: 1.5em 0 0.5em;

      .Search-no-results {
        padding: 0.5em 0 0.5em 3.5em;
      }
    }
  }

  .Search-content {
    &.StaticContent {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .page-content {
        position: absolute;
        top: 0;
        bottom: 0;
        padding-top: 4vh;
        height: 100%;
        overflow: auto;
      }
    }
  }

  &.active {
    width: 100%;
    height: 100%;
    left: 0 !important;
    top: 0 !important;

    @include breakpoint(mobile) {
      right: 0 !important;
    }

    .Search-overlay {
      position: fixed;
      width: 100%;
      height: 100%;
      opacity: 1;
      transition: opacity 0.4s 0.3s;
    }

    .Search-window {
      left: 50% !important;
      top: 5vh !important;
      transform: translateX(-50%);
      padding: 2em;
      width: 50em;
      max-height: 90vh;
      box-shadow: var(--shadow);
      background: var(--color-white);

      @include breakpoint(mobile) {
        top: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
        border-radius: 0;
        width: 100vw;
        max-width: unset;
        transform: none;
      }

      .Search-close,
      .Search-directory-toggle {
        display: block;
        opacity: 0;
        animation: fade_in 0.5s 0.2s forwards;
      }
      
      .Search-directory-toggle {
        display: flex;
      }

      .Search-input {
        margin-right: 9em;

        input,
        .icon {
          opacity: 1;
        }

        .icon {
          transition: all 0.2s;
        }
      }

      .Search-directory,
      .Search-results {
        display: block;
        max-height: calc(90vh - 8em);
        overflow: auto;
      }
    }
  }

  &.searching {
    @keyframes orbit {
      0% {
        transform: rotate(0deg) translate(-0.15em) rotate(0deg);
      }
      100% {
        transform: rotate(360deg) translate(-0.15em) rotate(-360deg);
      }
    }

    .icon {
      animation: 1s linear orbit infinite;
    }
  }

  &.no-results {
    @keyframes shake {
      0%,
      100% {
        transform: transtranslateX(-50%);
      }
      20%,
      60% {
        transform: translateX(-51%);
      }
      40%,
      80% {
        transform: translateX(-49%);
      }
    }

    @keyframes shake_mobile {
      0%,
      100% {
        transform: transtranslateX(0);
      }
      20%,
      60% {
        transform: translateX(0.5em);
      }
      40%,
      80% {
        transform: translateX(-0.5em);
      }
    }

    .Search-window {
      animation: 0.5s ease-in shake forwards;

      @include breakpoint(mobile) {
        animation: none;

        .Search-input {
          animation: 0.5s ease-in shake_mobile forwards;
        }
      }
    }
  }
}
