.DirectoryItem {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0;

  &-toggle {
    padding-top: 2px;
    cursor: pointer;
    color: var(--color-secondary);
  }

  &-children {
    flex-basis: 100%;
    padding-left: 30px;
  }
}