@import 'breakpoints';
@import 'keyframes';

@keyframes grid_box {
  0% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

.GridBox {
  position: relative;
  outline: calc(var(--grid-gap) * 0.5) solid var(--color-white);
  padding: calc(var(--grid-gap) * 0.5);
  color: var(--color-white);
  overflow: hidden;
  background-color: var(--grid-box-color);
  opacity: 0.6;
  animation: grid_box 0.3s forwards;
  transition: background-color 0.3s;

  @for $i from 1 through 10 {
    &:nth-child(#{$i}) {
      animation-delay: $i * 0.03s;
      transition-delay: $i * 0.03s;
    }

    &:nth-child(#{10 + $i}) {
      animation-delay: $i * 0.035s;
      transition-delay: $i * 0.035s;
    }

    &:nth-child(#{20 + $i}) {
      animation-delay: $i * 0.04s;
      transition-delay: $i * 0.04s;
    }
  }

  @include breakpoint(mobile) {
    overflow: auto;
    opacity: 1;
    animation: none;
  }

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    border: calc(var(--grid-gap) / 2) solid var(--color-white);
  }

  &:first-child {
    padding-top: 0;
  }

  img {
    max-width: 6em;
    height: auto;
  }

  .thumbnail {
    display: none;
  }

  h3 {
    margin: 0;
    font-size: 2vw;
    line-height: 1;
    font-weight: 600;
  }

  .title {
    font-weight: bold;
    line-height: 0.85;
  }

  .details {
    margin-top: 0.3em;
    font-size: 0.8em;
  }

  &.GridGroup,
  &.grid-image {
    cursor: pointer;
  }

  &-disabled {
    @include breakpoint(tablet, laptop, laptop_l, desktop) {
      background-color: #dbe2e5;
      background-image: none !important;
      cursor: default !important;

      & * {
        visibility: hidden;
      }
    }
  }
}